










import { Component, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'

@Component({
    name: 'SubscriberAreaError',
    components: {
        VsContainer,
        VsSectionHeader,
    },
})
export default class extends Vue {
}
